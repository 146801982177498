











  import { Vue, Component } from 'nuxt-property-decorator'
  import { getIdFromUrl } from 'vue-youtube'
  import { Prop } from 'vue-property-decorator'

  @Component
  export default class OneCmsYoutubeComponent extends Vue {
    name: string = 'one-cms-youtube-component'

    @Prop({
      required: true,
      type: String,
    })
    videoId!: string;

    @Prop({
      type: String,
    })
    width?: string;

    @Prop({
      type: String,
    })
    height?: string;

    @Prop({
      type: Boolean,
    })
    fitParent?: boolean;

    getYoutubeId(url: string) {
      return getIdFromUrl(url) || url
    }
  }
